import React from 'react';
import { ButtonImage } from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import UniverseSelector from '../../helpers/UniverseSelector';
import useAvailableChannel from '../App/hooks/useAvailableChannel';
import { LoaderBlock } from '@womcorp/wom-miwom-ui-kit/dist/components/Loader';

const ChannelButtons = () => {
  const availableChannels = useAvailableChannel();

  if (!availableChannels) return <LoaderBlock />;

  return (
    <>
      {availableChannels.mobile && (
        <ButtonImage image="phone" onClick={() => UniverseSelector('phone')}>
          Telefonía<br/>Móvil
        </ButtonImage>
      )}
      {availableChannels.bam && (
        <ButtonImage image="bam" onClick={() => UniverseSelector('bam')}>
          Internet<br/>Móvil
        </ButtonImage>
      )}
      {availableChannels.fiber && (
        <ButtonImage image="fiber" onClick={() => UniverseSelector('fiber')}>
          Internet<br/>Fibra
        </ButtonImage>
      )}
      {availableChannels.tv && (
        <ButtonImage image="tv" onClick={() => UniverseSelector('tv')}>
          WOM<br/>TV
        </ButtonImage>
      )}
    </>
  );
};

export default ChannelButtons;
