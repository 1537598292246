import React, { useEffect } from 'react';
import Typography from '@womcorp/wom-miwom-ui-kit/dist/components/Typography';
import { TextSlider } from '@womcorp/wom-miwom-ui-kit/dist/components/Slider';
import {
  LogoSVG
} from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/miscellaneous';
import WrapperStyled, {
  TypographyContainerStyled
} from '../components/Login/styles/IndexStyled';
import RainbowLaneStyled from '../components/Login/styles/RainbowLaneStyled';
import TagManager from '../common/TagManager';
import ChannelButtons from '../components/Login/ChannelButtons';

const Index = () => {
  useEffect(() => {
    TagManager.VirtualPage({
      page: '/sso',
      pagename: 'SSO_ONLOAD'
    });
  }, []);

  return (
    <WrapperStyled>
      <RainbowLaneStyled />
      <section>
        <div>
          <TextSlider minHeight="240px">
            <TypographyContainerStyled>
              <Typography type="Body" weight="bold" typeDesktop="Titulo26">
                Maneja tus cuentas fácil y rápido
              </Typography>
              <Typography type="Caption" weight="regular" typeDesktop="BigBody">
                Revisa el estado de tus servicios, paga tu cuenta y mucho más.
              </Typography>
            </TypographyContainerStyled>
            <TypographyContainerStyled>
              <Typography type="Body" weight="bold" typeDesktop="Titulo26">
                Cámbiate al plan Más conveniente
              </Typography>
              <Typography type="Caption" weight="regular" typeDesktop="BigBody">
                Mira los planes que WOM tiene para ti y cámbiate en menos de 5 minutos.
              </Typography>
            </TypographyContainerStyled>
            <TypographyContainerStyled>
              <Typography type="Body" weight="bold" typeDesktop="Titulo26">
                Revisa cuándo llegarán tus compras hechas en WOM
              </Typography>
              <Typography type="Caption" weight="regular" typeDesktop="BigBody">
                Podrás ver el estado de todas tus compras hechas en la tienda WOM y cuando se hará el despacho a domicilio.
              </Typography>
            </TypographyContainerStyled>
          </TextSlider>
        </div>
      </section>
      <aside>
        <div>
          <header>
            <LogoSVG />
          </header>
          <section>
            <Typography type="BigBody" weight="bold">
              Selecciona un servicio para acceder:
            </Typography>
          </section>
          <footer>
            <ChannelButtons />
          </footer>
        </div>
      </aside>
    </WrapperStyled>
  );
};

export default Index;
